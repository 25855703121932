/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        // Make the menu work on hover
        var screenWidth = $(window).width();
        var stickyOpen = false;
        var hoverEnabled = true;
        $('.collapse').not('.in')
          .parents('.panel')
          .find('a')
          .addClass('collapsed');

        if (screenWidth > '768') {
          $('ul.nav li.dropdown').hover(function() {
            $(this).find('.dropdown-menu').stop(true, true).fadeIn(0);
            $(this).addClass('hover');
          }, function() {
            $(this).find('.dropdown-menu').stop(true, true).fadeOut(0);
            $(this).removeClass('hover');
          });
        }

        $('.main-header ul.nav > li.dropdown > ul.dropdown-menu > li')
          .hover(function() {
            $(this).parents('.hover').children('a').css('color', 'white');
          }, function() {
            $(this).parents('.hover').children('a').css('color', '#d7d7d7');
          });
        $('.main-header ul.nav > li.dropdown > a')
          .hover(function() {
            $(this).css('color', 'white');
          }, function() {
            $(this).css('color', '#d7d7d7');
          });

        /**************************************
         * SYNXIS FORM
         ************************************/
        // Initialize datepicker form form
        $('.datepicker').datepicker({
          orientation: 'bottom'
        });

        // Sticky nav functionality
        var notHotelLandingPage = $('.sticky-nav')
                                  .parent('.not-hotel-landing-page');
        var danburyForm = $('.not-hotel-landing-page .sticky-nav.danbury');
        function closeSticky() {
          if (stickyOpen) {
            if (notHotelLandingPage.length > 0) {

              $('.not-hotel-landing-page .sticky-nav')
                .css('transform', 'translateX(-770px)');
            } else {
              $('.sticky-nav')
                  .css('transform', 'translateX(-929px)');
            }
            stickyOpen = false;
          }
        }
        if ($('#reservation-form').length > 0) {
          $('#reservation-form').on( 'hover', function() {
            if (hoverEnabled) {
              stickyOpen = true;
              if (notHotelLandingPage.length > 0) {
                $('.not-hotel-landing-page .sticky-nav')
                  .css('transform', 'translateX(-18px)');
              } else {
                $('.sticky-nav')
                    .css('transform', 'translateX(0)');
              }
            }
          });

          $('.booking-form .close-button').on('click', function(event) {
            event.preventDefault();
            hoverEnabled = false;
            closeSticky();
            setTimeout(function() { hoverEnabled = true;}, 1000);
          });
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        // change 'revapi1' to whatever revapi number is being used for your slider

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
